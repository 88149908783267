<template>
  <!--srcviewscompanydetailsBackgroundProfilevuehtmlStart-->

  <div id="defaultId1" class="utsAVXw">
    <!-- 左侧Company -->
    <div id="g9b4d5" :class="obj?.alterList?.length>0?'uzs5SV7':'uzs5SV7 uzs5SV7100'">
      <div id="g28a52" class="ue66f5 detail_title_20">Company Profile</div>
      <div id="g3b754" class="u45402">
        <div id="g24d02" class="unkPfIK u4TrKUw">
          <contentText v-if="obj.companyName != '-'"
            id="idGMUIrf"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Company Name"
            contentAlign="flex-start"
          >
            <div id="g808c6" class="u30501">{{ obj.companyName }}</div>
          </contentText>
          <contentText v-if="obj.companyNameNative != '-'"
            id="ca364f"
            class="uJQHsXz"
           titleWidth="12.71em"
            marginRight="2.14em"
            label="Native Name"
            contentAlign="flex-start"
          >
            <div id="cf0bcc" class="u30501">{{ obj.companyNameNative }}</div>
          </contentText>
          <contentText v-if="obj.otherName != '-'"
            id="c4d135"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Other Name"
            contentAlign="flex-start"
          >
            <div id="c15ba9" class="u30501">{{ obj.otherName }}</div>
          </contentText>
          <contentText v-if="obj.companyID != '-'"
            id="cb5947"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Company ID"
            contentAlign="flex-start"
          >
            <div id="c445a6" class="u30501">{{ obj.companyID }}</div>
          </contentText>
          <contentText v-if="obj.vATNumber != '-'"
            id="cdd55c"
            class="uJQHsXz"
           titleWidth="12.71em"
            marginRight="2.14em"
            label="VAT Number"
            contentAlign="flex-start"
          >
            <div id="cbab2b" class="u30501">{{ obj.vATNumber }}</div>
          </contentText>
          <contentText v-if="obj.stockCode != '-'"
            id="ce6a0a"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Stock Code"
            contentAlign="flex-start"
          >
            <div id="c004aa" class="u30501">{{ obj.stockCode }}</div>
          </contentText>
          <contentText v-if="obj.listed != '-'"
            id="cb1ea4"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Listed"
            contentAlign="flex-start"
          >
            <div id="cebab7" class="u30501">{{ obj.listed }}</div>
          </contentText>
          <contentText v-if="obj.updateTime != '-'"
            id="c656b1"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Last Update"
            contentAlign="flex-start"
          >
            <div id="c35ab5" class="u30501">{{ obj.updateTime }}</div>
          </contentText>
          <contentText v-if="obj.dataSource.length > 0"
            id="cd94fc"
            class="uJQHsXz"
            titleWidth="12.71em"
            marginRight="2.14em"
            label="Data Source"
            contentAlign="flex-start"
          >
            <div class="txt"  style="text-decoration: underline;color: #1290c9; cursor: pointer;" v-for="(item,index) in obj.dataSource" :key="index" @click="setid(item.website)">{{ item.register }}</div>
          </contentText>
          <contentText v-if="obj.registrationDate != '-'"
            id="c0b661"
            class="uJQHsXz"
           titleWidth="12.71em"
            marginRight="2.14em"
            label="Registration Date"
            contentAlign="flex-start"
          >
            <div id="c0afd9" class="u30501">{{ obj.registrationDate }}</div>
          </contentText>
          <contentText v-if="obj.legalForm != '-'"
            id="c43947"
            class="uJQHsXz"
           titleWidth="12.71em"
            marginRight="2.14em"
            label="Legal Form"
            contentAlign="flex-start"
          >
            <div id="c23793" class="u30501">{{ obj.legalForm }}</div>
          </contentText>
          <contentText v-if="obj.companyType != '-'"
            id="cdee4f"
            class="uJQHsXz upTxhMN"
        titleWidth="12.71em"
            marginRight="2.14em"
            label="Company Type"
            contentAlign="flex-start"
          >
            <div id="c5ee3a" class="u30501 uKVgfIN">{{ obj.companyType }}</div>
          </contentText>
        </div>
        <div id="g5376f" class="u33a47">
          <contentText v-if="obj.legalStatus != '-'"
            id="caa5e8"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Legal Status"
            contentAlign="flex-start"
          >
            <div id="ce84f6" class="u30501">{{ obj.legalStatus }}</div>
          </contentText>
          <contentText v-if="obj.regCapital != '-'"
            id="ceac9f"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Registered Capital"
            contentAlign="flex-start"
          >
            <div id="cdf16c" class="u30501">{{ obj.regCapital }}</div>
          </contentText>
          <contentText v-if="obj.paidUpCapital != '-'"
            id="cd3ce6"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Paid-in Capital"
            contentAlign="flex-start"
          >
            <div id="cd0255" class="u30501">{{ obj.paidUpCapital }}</div>
          </contentText>
          <contentText v-if="obj.jurisdiction != '-'"
            id="cd4e7f"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Jurisdiction"
            contentAlign="flex-start"
          >
            <div id="cf9fee" class="u30501">{{ obj.jurisdiction }}</div>
          </contentText>
          <contentText v-if="obj.address != '-'"
            id="c18d61"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Registered Address"
            contentAlign="flex-start"
          >
            <div id="c2ef1e" class="u30501">{{ obj.address }}</div>
          </contentText>
          <contentText v-if="obj.district != '-'"
            id="c29cdc"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="County/City"
            contentAlign="flex-start"
          >
            <div id="c24963" class="u30501">{{ obj.district }}</div>
          </contentText>
          <contentText v-if="obj.province != '-'"
            id="c29cdc"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Province/State"
            contentAlign="flex-start"
          >
            <div id="c24963" class="u30501">{{ obj.province }}</div>
          </contentText>
          <contentText v-if="obj.country != '-'"
            id="c139d0"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Country/Region"
            contentAlign="flex-start"
          >
            <div id="c4eaef" class="u30501">{{ obj.country }}</div>
          </contentText>
          <contentText v-if="obj.postCode != '-'"
            id="ca8873"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Zip Code"
            contentAlign="flex-start"
          >
            <div id="c984c5" class="u30501">{{ obj.postCode }}</div>
          </contentText>
          <contentText v-if="obj.operatingAddress != '-'"
            id="ce5232"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Operating Address"
            contentAlign="flex-start"
          >
            <div id="c455f8" class="u30501">{{ obj.operatingAddress }}</div>
          </contentText>
          <contentText v-if="obj.operatingProvince != '-'"
            id="c1bebc"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Province/State"
            contentAlign="flex-start"
          >
            <div id="c58a9e" class="u30501">{{ obj.operatingProvince }}</div>
          </contentText>
          <contentText v-if="obj.operatingCountry != '-'"
            id="c6d1a0"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Country/Region"
            contentAlign="flex-start"
          > 
            <div id="c91807" class="u30501">{{ obj.operatingCountry }}</div>
          </contentText>
          <contentText v-if="obj.operatingPostCode != '-'"
            id="c0db8a"
            class="uJQHsXz"
            titleWidth="13.57em"
            marginRight="2.14em"
            label="Zip Code"
            contentAlign="flex-start"
          >
            <div id="cc5a91" class="u30501">{{ obj.operatingPostCode }}</div>
          </contentText>
        </div>
      </div>
      <div id="cd6ea6" class="u45402"></div>
    </div>
    <!-- 右侧History -->
    <div id="g7b671" class="uMqaGdJ" v-if="obj?.alterList?.length > 0">
      <div id="g92544" class="u6UtUkg">
        <div id="ge6ba6" class="uf472b detail_title_20">History</div>
      </div>
      <div id="g1d9f3" class="u13dd6">
        <timeLine
          id="gfc75b"
          v-for="(item, index) in obj.alterList"
          class="u3c331"
          :time="item.alterDate"
          :name="item.name"
          :text="item.afterContent"
          :key="index"
        >
        </timeLine>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
  <!--srcviewscompanydetailsBackgroundProfilevuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import { getCompanyProfile, } from "@/api/companyApi.js";
import contentText from "../../../../components/text_content/index.vue";
import timeLine from "../../../../components/timeLine/index.vue";
import ReLogin from "../../../../components/ReLogin.vue";
//importEnd

export default {
  name: "",
  props: {
    // srcviewscompanydetailsBackgroundProfilevuePropsStart
    // srcviewscompanydetailsBackgroundProfilevuePropsEnd
  },
  // created() {
    // this.$bus.$on('authCode', (status) => {
    //         this.reLoginVis = status;
    //     });
    // },
  mounted() {
    this.getCompanyProfilelist();
    this.$emit("UpdatamarkTotalList", []);
    // defaultLoad
    // srcviewscompanydetailsBackgroundProfilevueMountedStart

    // srcviewscompanydetailsBackgroundProfilevueMountedEnd
  },

  components: {
    timeLine,
    contentText,
    ReLogin,
  },

  data() {
    return {
      reLoginVis: false,
      // insertData
      // srcviewscompanydetailsBackgroundProfilevueDataStart

      // titleStyle
      obj:{
        dataSource: [],
      },
      titleStyle: {
        width: "11.71em",
        marginRight: "2.14em",
      },
      // timeLineData
      timeLineData: [
        {
          time: "02-21-1997",
          text: "The company is registered as a limited liability company with the local Administration for industry and Commerce.",
          name: "Registry",
        },
        {
          time: "05-15-1999",
          text: "Shanganxin has obtained the Shanganxin(Shanghai) CPM System Information Security Level Evaluation Report issued by the Information Security Level Protection Evaluation Center of the Ministry of Public Security to evaluate the companys Shanganxin (Shanghai)CPM System security protection level as the firstlevel.",
          name: "Capital",
        },
        {
          time: "03-01-2006",
          text: "2102339048920348549342H",
          name: "Appointment",
        },
        {
          time: "12-31-2011",
          text: "350203200471089",
          name: "Finance",
        },
        {
          time: "02-21-2020",
          text: "09-23-2008",
          name: "Address",
        },
        {
          time: "02-21-1997",
          text: "The company is registered as a limited liability company with the local Administration for industry and Commerce.",
          name: "Registry",
        },
        {
          time: "05-15-1999",
          text: "Shanganxin has obtained the Shanganxin(Shanghai) CPM System Information Security Level Evaluation Report issued by the Information Security Level Protection Evaluation Center of the Ministry of Public Security to evaluate the companys Shanganxin (Shanghai)CPM System security protection level as the firstlevel.",
          name: "Capital",
        },
        {
          time: "03-01-2006",
          text: "2102339048920348549342H",
          name: "Appointment",
        },
        {
          time: "12-31-2011",
          text: "350203200471089",
          name: "Finance",
        },
        {
          time: "02-21-2020",
          text: "09-23-2008",
          name: "Address",
        },
      ],
      // srcviewscompanydetailsBackgroundProfilevueDataEnd
    };
  },
  methods: {
    getCompanyProfilelist() {
      const id3a = this.$route.query.id3a;
      getCompanyProfile({
        id3a: id3a,
      }).then((res) => {
        this.obj = res.data;
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });
    },
        setid(row) {
if(row.includes('http')){
    let routeData=row
    window.open(routeData, '_blank');
}else{
    let routeData='http://'+row
window.open(routeData, '_blank');
}

},

    // srcviewscompanydetailsBackgroundProfilevueMethodEnd
  },
  computed: {},
};
</script>
<style scoped>
.srcviewscompanydetailsBackgroundProfilevuecssStart {
}

.utsAVXw {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.uzs5SV7 {
  border-radius: 0.2857142857142857em;
  padding-left: 2.14em;
  padding-right: 2.14em;
  padding-bottom: 2.14em;
  padding-top: 2.14em;
  box-shadow: 0em 0.14285714285714285em 1em 0em rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  margin-bottom: 1.42em;
  width: calc(100% - 37.285714285714285em - 0.71em);
}
.uzs5SV7100{
  width: 100%;
}
.ue66f5 {
  font-size: 2.14em;
  font-weight: 400;
  font-family: 'DMSans Bold';
  color: rgb(26, 35, 50);
}

.u45402 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.u4TrKUw {
  width: 50%;
  margin-top: 2.14em;
}

.uJQHsXz {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.92em;
  line-height: 2.14em;
}

.u30501 {
  font-size: 1.42857142857143rem;
  color: #29445D !important;
}
.txt{
  font-size: 1.42857142857143rem;
}
.u47845 {
  font-size: 1.4285714285714286em;
  color: rgb(18, 144, 201);
  text-decoration: underline;
}

.u33a47 {
  width: 50%;
  margin-left: 7.14em;
  margin-top: 2.14em;
}

.uMqaGdJ {
  width: 37.285714285714285em;
  padding-top: 2.14em;
  padding-left: 2em;
  background: #ffffff;
  margin-left: 0.71em;
  margin-bottom: 1.42em;
  border-radius: 0.2857142857142857em;
  box-shadow: 0em 0.14285714285714285em 1em 0em rgba(0, 0, 0, 0.12);
}

.u6UtUkg {
  margin-bottom: 1.42em;
}

.uf472b {
  font-size: 2.14em;
  font-family: 'DMSans Bold';
}

.u13dd6 {
  width: auto;
  height: 59.642857142857146em;
  padding-left: 0.5em;
  padding-right: 2em;
  overflow-y: scroll;
  overflow-x: initial;
}

.u3c331 {
  box-sizing: content-box;
  overflow-y: initial;
  color: #1A2332;
}

.srcviewscompanydetailsBackgroundProfilevuecssEnd {
}


</style>